// A common prefix that is used for authenticated users
export const BASE_ROUTE = '/app';
export const CPOHQ_ROUTE_NAME = 'cpohq';
export const PREFERENCES_ROUTE_NAME = 'settings';
export const ADMIN_ROUTE_NAME = 'admin';
const MEMBER_ROUTE_NAME = 'member';
const GUEST_ROUTE_NAME = '/guest';

// BENCHMARK ROUTES
export const BENCHMARKS_ROUTE_NAME = 'benchmarks';

export default {
  // Public routes
  login: '/login',
  loginCallback: '/login/callback',
  signUp: '/sign-up',
  keyDrop: '/keydrop',
  changeEmail: '/change-email',

  // Main routes
  home: `${BASE_ROUTE}`,
  customDashboards: `${BASE_ROUTE}/dashboards`,
  customDashboard: `${BASE_ROUTE}/dashboards/:id?`,
  feed: `${BASE_ROUTE}/notifications`,
  employees: `${BASE_ROUTE}/employees`,
  orgChart: `${BASE_ROUTE}/org-chart`,

  // Analyze dropdown routes
  benchmarks: `${BASE_ROUTE}/${BENCHMARKS_ROUTE_NAME}`,
  explorer: `${BASE_ROUTE}/explorer`,
  compensation: `${BASE_ROUTE}/compensation`,
  attrition: `${BASE_ROUTE}/attrition`,
  recruiting: `${BASE_ROUTE}/recruiting`,
  recruitingPipeline: `${BASE_ROUTE}/recruiting-pipeline`,
  deiAnalytics: `${BASE_ROUTE}/dei-analytics`,
  dataIssues: `${BASE_ROUTE}/data-issues`,

  // Stories
  stories: `${BASE_ROUTE}/stories`,
  story: `${BASE_ROUTE}/stories/:id`,

  // Others
  profileRaw: `${BASE_ROUTE}/profile`,
  profile: `${BASE_ROUTE}/profile/:id/:tab?`,
  reports: `${BASE_ROUTE}/reports`,

  notFound: `${BASE_ROUTE}/not-found`,
  notAuthorized: `${BASE_ROUTE}/not-authorized`,

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // NESTED ROUTES DEFINITION BELOW
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // CPO Hub routes
  cpoHub: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}`,
  cpoHubAnalyticsBenchmarkLanding: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/benchmarks-landing`,
  cpoHubAnalyticsBenchmark: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/${BENCHMARKS_ROUTE_NAME}`,
  cpoHubSearch: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/search`,
  cpoHubForumThread: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/post/:id`,
  cpoHubNetwork: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/network`,
  cpoHubPollSuggestView: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/polls/suggest`,
  cpoHubPoll: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/polls/:id`,
  cpoHubPolls: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/polls`,
  cpoHubArticlePreview: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/library/preview`,
  cpoHubLibrary: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/library`,
  cpoHubArticle: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/library/:id`,
  cpoHubCeoLetter: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/ceo-letter`,
  cpoHubCpoSessions: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/cpo-sessions`,
  cpoHubMasterclasses: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/ai-masterclass`,
  cpoHubMasterclass: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/ai-masterclass/:id`,
  cpoHubCpoSession: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/cpo-sessions/:id`,
  cpoHubTenishTips: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/tenish-tips`,
  cpoHubEvent: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/events/:id`,
  cpoHubEvents: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/events`,
  cpoHubProfile: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/${MEMBER_ROUTE_NAME}/:id?`,
  cpoHubContent: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/content/:id`,
  cpoHubStripeSuccess: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/stripe-success`,
  cpoHubStripeCancel: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/stripe-cancel`,

  // cpohq analytics demo
  cpoHubAnalyticsDemo: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/analytics/demo`,
  cpoHubAnalyticsExplorerDemo: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/analytics/demo/explorer`,
  cpoHubAnalyticsAttritionDemo: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/analytics/demo/attrition`,
  cpoHubAnalyticsDeiDemo: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/analytics/demo/deiAnalytics`,
  cpoHubAnalyticsCompensationDemo: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/analytics/demo/compensation`,
  cpoHubAnalyticsRecruitingDemo: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/analytics/demo/recruiting`,
  cpoHubEmployeesDemo: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/employees/demo`,
  cpoHubOrgChartDemo: `${BASE_ROUTE}/${CPOHQ_ROUTE_NAME}/org-chart/demo`,

  // Admin Settings
  admin: `${BASE_ROUTE}/${ADMIN_ROUTE_NAME}`,
  adminManageUsers: `${BASE_ROUTE}/${ADMIN_ROUTE_NAME}/manage-users`,

  // Preferences
  preferences: `${BASE_ROUTE}/${PREFERENCES_ROUTE_NAME}`,
  preferencesAddField: `${BASE_ROUTE}/${PREFERENCES_ROUTE_NAME}/add-field`,
  preferencesAddUser: `${BASE_ROUTE}/${PREFERENCES_ROUTE_NAME}/add-user`,
  preferencesManageFields: `${BASE_ROUTE}/${PREFERENCES_ROUTE_NAME}/?tab=fields`,
  preferencesPermissionGroup: `${BASE_ROUTE}/${PREFERENCES_ROUTE_NAME}/permission-group/:id?`,
  preferencesPermissionGroups: `${BASE_ROUTE}/${PREFERENCES_ROUTE_NAME}/?tab=permission-groups`,

  // Add system
  preferencesIntegrations: `${BASE_ROUTE}/${PREFERENCES_ROUTE_NAME}?tab=integrations-self-serve`,
  prefencesIntegrationVendor: `${BASE_ROUTE}/${PREFERENCES_ROUTE_NAME}/integrations/vendor/:vendor`,
  prefencesIntegrationSystem: `${BASE_ROUTE}/${PREFERENCES_ROUTE_NAME}/integrations/system/:system`,

  // Guests
  guest: `${GUEST_ROUTE_NAME}`,
  guestReport: `${GUEST_ROUTE_NAME}/report/:id?`,
  guestCustomDashboard: `${GUEST_ROUTE_NAME}/dashboards/:id?`,
  guestProfile: `${GUEST_ROUTE_NAME}/profile`,

  // Genie
  genie: `${BASE_ROUTE}/genie`,
  genie2: `${BASE_ROUTE}/genie2`,
  genie3: `${BASE_ROUTE}/genie3`,

  // DEPRECATED – TBD
  // requisition: `${BASE_ROUTE}/requisition/:id?`,
};
